<template>
  <div style="position: relative;  z-index: 0;">
    <v-card flat>
    <v-card-title>
      Locations (select checkbox to edit)
      <v-spacer/>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        dense
        outlined
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="locations"
      :search="search"
      :sort-by="['locationName']"
      single-select
      class="elevation-1"
      show-select
      v-model="selected"
    >
      <template v-slot:[`item.vaccineManufacturerId`]="{ item }">
        <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName" v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
      </template>
      <template v-slot:[`item.vaccineDoseType`]="{ item }">
        <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
      </template>
    </v-data-table>
  </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('vaccineLocations', ['locations'])
  },
  components: {
    manufacturerChip: () => import('../../../../vaccineManufacturer/components/manufacturerChip'),
    doseTypeChip: () => import('../../../../vaccineManufacturer/components/doseTypeChip')
  },
  data () {
    return {
      search: null,
      selected: [ ],
      headers: [
        { text: 'Location', value: 'locationName' },
        { text: 'Address', value: 'address' },
        { text: 'Phone', value: 'phone' },
        { text: 'Manufacturer', value: 'vaccineManufacturerId' },
        { text: 'Dose Type', value: 'vaccineDoseType' }
      ]
    }
  },
  methods: {
  },
  mounted: function () {
  },
  watch: {
    selected: function (newValue, oldValue) {
      this.$emit('updateLocationForm', newValue)
    }
  }
}
</script>
